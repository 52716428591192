
<template>
    <div class="temp-msg-container">
        <div v-if="tempMsg" v-html="tempMsg"></div>
        <div v-else class="msg-empty">
            <empty-box text="消息过期啦"/>
        </div>
    </div>
</template>

<script>
import {getTempMsg} from "@/api/file";

export default {
    name: 'index',
    data() {
        return {
            tempMsgId: '',
            tempMsg: ''
        }
    },
    inject: ['setLoading'],
    created() {
        const tempMsgId = this.$route.params.id
        if (tempMsgId) {
            this.tempMsgId = tempMsgId
            this.getData()
        }
    },
    methods: {
        getData() {
            this.setLoading(true)
            getTempMsg(this.tempMsgId).then(res => {
                if (res.data) {
                    this.tempMsg = res.data.replace(/\n/g, '<br/>')
                } else {
                    this.tempMsg = ''
                }
            }).finally(()=> {
                this.setLoading(false)
            })
        }
    }
}
</script>

<style scoped lang="less">
.temp-msg-container {
    padding: 40px 20px;
    text-align: justify;
    font-size: 18px;
    box-sizing: border-box;
    min-height: 200px;
    .msg-empty {
        position: absolute;
        left: 0;
        top: 200px;
        height: 100%;
        width: 100%;
    }
}
</style>
