import request from "@/util/request";


/**
 * 上传文件
 * @param file 文件对象
 * @returns {*}
 */
export function upload(file) {
    let formData = new FormData()
    formData.append('file', file)
    return request({
        url: '/file/upload',
        method: 'post',
        headers: {"Content-Type": 'multipart/form-data'},
        data: formData
    })
}

/**
 * 获取临时消息
 * @param tempMsgId
 * @returns {*}
 */
export function getTempMsg(tempMsgId) {
    return request({
        url: '/file/get_temp_msg',
        method: 'get',
        params: {tempMsgId}
    })
}
