import Vue from 'vue'
import Router from 'vue-router'
import Content from "@/layout/HomePage/components/Content"
import ListArticle from "@/view/original/ListArticle"
import MarkDownEditer from '@/components/MarkDownEditer'
import InfoArticle from "@/view/original/InfoArticle/index"
import TimeLine from "@/view/original/TimeLine"
import AsMe from "@/view/original/AsMe";
import ChatWindow from "@/view/other/ChatWindow"
import TempMsg from "@/view/other/TempMsg"
import constant from "@/assets/js/constant";

Vue.use(Router)

const constantRoutes = [
    {
        path: '/',
        component: Content,
        meta: { keepAlive: true }
    },
    {
        path: '/timeline',
        component: TimeLine,
        meta: { keepAlive: true }
    },
    {
        path: '/as',
        component: AsMe,
    },
    {
        path: '/article',
        component: ListArticle,
    },
    {
        path: '/markdown',
        component: MarkDownEditer,
    },
    {
        path: '/article/:id',
        component: InfoArticle,
    },
    {
        path: '/talker',
        component: ChatWindow,
        meta: { includeLayout: false }
    },
    {
        path: '/temp/msg/:id',
        component: TempMsg,
        meta: { includeLayout: false }
    },
]

const router = new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

// router.beforeEach((to, from, next) => {
//     next()
// })

router.$toArticle = (article, isNewPage = false) => {
    if (article.contentType === constant.articleContentType.LINK) {
        window.open(article['linkUrl'], '_blank')
    }
     else if ([constant.articleContentType.MD,
        constant.articleContentType.ML]
        .includes(article.contentType)) {
        if (isNewPage) {
            window.open(router.resolve({
                path: '/article/' + article.id
            }).href, '_blank')
        } else {
            router.push({
                path: '/article/' + article.id
            })
        }
    }
}



export default router
